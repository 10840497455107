<template>
  <div class="mt-base">
    <div class="vx-row mb-3">
      <div class="vx-col w-full">
        <div class="w-full border border-solid d-theme-border-grey-light h-screen-50 overflow-y-auto">
          <table class="w-full table-fixed border-collapse">
            <thead class="font-extrabold border border-solid d-theme-border-grey-light">
              <tr class="d-theme-dark-bg border border-solid d-theme-border-grey-light">
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap w-10">#</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap">Nama</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap">Email</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap">Telp</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap">Status</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap">Keterangan</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap">Sent At</th>
              </tr>
            </thead>
            <tbody class="font-medium">
              <tr v-for="(item, index) in initData.recipients" :key="index">
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left whitespace-no-wrap">{{ index + 1 }}</td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left whitespace-no-wrap">{{ item.nama }}</td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left whitespace-no-wrap">{{ item.email }}</td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left whitespace-no-wrap">{{ item.telp }}</td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left whitespace-no-wrap">{{ item.status }}</td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.keterangan }}</td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left whitespace-no-wrap">{{ item.sent_at }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TabRecipients',
  computed: {
    storePengajuanMediaShow () {
      return this.$store.state.marketing.pengajuanMediaShow
    },
    initData: {
      get () {
        return this.storePengajuanMediaShow.initData
      },
      set (value) {
        this.$store.commit('marketing/pengajuanMediaShow/SET_INIT_DATA', value)
      }
    }
  },
  data () {
    return {}
  },
  methods: {}
}
</script>
